<template>
  <div
    id="home"
    class="flex items-center bg-custom-blue min-h-screen h-auto pt-16 pb-6 md:pt-[72px] bg-none hero-bg dark:bg-custom-blue-dark"
  >
    <div class="container lg:flex lg:items-center">
      <div class="lg:flex-1 lg:order-1">
        <img
          :src="'/imgs/' + homePage.hero.img_sm"
          alt=""
          class="w-9/12 sm:w-8/12 lg:w-10/12 mx-auto"
        />
      </div>
      <div
        class="prose prose-black px-8 sm:px-12 md:px-16 lg:px-0 pt-3 lg:pt-0 flex-1 xl:flex-none"
      >
        <h1
          class="text-xl sm:text-2xl md:text-3xl xl:text-4xl text-black text-font-heavy uppercase dark:text-white mb-1"
          v-html="homePage.hero.title"
        ></h1>
        <span v-html="homePage.hero.subtitle" class="dark:text-white"> </span>
        <!-- <span class="text-md md:text-xl xl:text-2xl"
          >Powered by A<span class="lowercase">i</span> and NFT<span
            class="lowercase"
            >s</span
          ></span
        >
        <br />
        <div
          class="text-font-regular text-xl md:text-2xl xl:text-3xl dark:text-white mt-5"
        >
          We show you how to own and earn from your data.
        </div> -->
        <ul
          v-if="homePage.hero.features"
          class="uppercase text-sm md:text-md xl:text-lg font-semibold list-style-sparke dark:text-white"
        >
          <li v-for="(item, i) in homePage.hero.features" :key="'feature' + i">
            {{ item }}
          </li>
        </ul>
        <div class="mt-8 lg:mt-11 uppercase">
          <a
            :href="homePage.hero.btn1_link"
            class="inline-flex items-center text-custom-1 font-small sm:font-medium py-2 px-4 sm:py-3 sm:px-7 btn-primary mr-1 sm:mr-2"
            v-html="homePage.hero.btn1"
          >
          </a>
          <!-- <a
            :href="homePage.hero.btn2_link"
            class="inline-block text-custom-1 font-small sm:font-medium py-2 px-3 sm:py-3 sm:px-6 btn-outline mt-2 md:mt-0"
            v-html="homePage.hero.btn2"
          >
          </a>  -->
        </div>
      </div>
    </div>
  </div>
  <!-- ====== Hero Section End -->

  <!-- ====== manual Section Start -->
  <!--
  <section class="pt-20 lg:pt-[100px]">
    <div class="container px-4 sm:px-8 md:px-12 lg:px-18">
      <div class="text-center mb-16">
        <div class="w-full px-4">
          <div class="">
            <span
              class="font-semibold text-lg sm:text-xl md:text-2xl text-gray-700 mb-2 block uppercase dark:text-gray-400"
            >
              {{ homePage.manual.miniTitle }}
            </span>
            <h2
              class="text-font-heavy text-2xl sm:text-[30px] md:text-[40px] text-dark mb-4 uppercase dark:text-white"
            >
              {{ homePage.manual.title }}
            </h2>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        <div
          class="w-full px-4"
          v-for="(item, i) in homePage.manual.steps"
          :key="'step' + i"
        >
          <div class="mb-12 group">
            <div class="mb-5">
              <img
                :src="'imgs/' + item.img"
                alt=""
                class="w-full dark:invert"
              />
            </div>
            <h4
              class="text-font-bold text-lg sm:text-xl text-dark mb-3 dark:text-white"
            >
              {{ item.title }}
            </h4>
            <p
              class="text-black-700 text-lg mb-5 lg:mb-11 min-h-[100px] dark:text-gray-300 text-sm sm:text-base text-justify"
            >
              {{ item.desc }}
            </p>
            <a
              :href="item.link"
              class="font-medium text-sm sm:text-base py-2 duration-200 btn-border-bottom hover:px-2 uppercase dark:text-white"
            >
              Find out more
              <img
                src="imgs/arrow-right.svg"
                alt=""
                class="ml-2 inline-block dark:invert"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>                     -->
  <!-- ====== manual Section End -->

  <!-- ====== NFTs Section Start
   <section class="pt-20">
    <div
      class="container px-8 sm:px-12 md:px-16 lg:px-18 pt-10 pb-16 bg-custom-blue dark:bg-custom-blue-dark rounded-[30px]"
    >
      <div class="text-center mb-20">
        <div class="w-full sm:px-4">
          <div class="">
            <span
              class="font-heavy text-lg sm:text-xl md:text-2xl text-gray-700 mb-2 block dark:text-gray-300"
            >
              {{ homePage.nfts.miniTitle }}
            </span>
            <h2
              v-html="homePage.nfts.title"
              class="text-font-regular text-lg xs:text-2xl sm:text-[30px] md:text-[40px] text-dark mb-4 dark:text-white"
            ></h2>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        <div
          class="w-full"
          v-for="(item, i) in homePage.nfts.nfts"
          :key="'nft' + i"
        >
          <div class="bg-white rounded-[28px] mb-4 md:mb-0 dark:bg-custom-dark">
            <div class="">
              <img
                :src="'imgs/' + item.img"
                alt=""
                class="w-full relative object-cover"
              />
            </div>
            <div class="px-4 xs:px-7 sm:px-9 relative overflow-hidden pb-7">
              <div class="mb-2">
                <div class="dark:text-white" v-if="item.suptitle">
                  <span
                    class="w-[12px] h-[12px] bg-[#6DE4AE] rounded-full inline-block relative top-[6px] shadow-[0_0_6px_1px_#6DE4AE] mr-2"
                    >&nbsp;</span
                  >
                  {{ item.suptitle }}
                </div>
                <br v-else />
              </div>
              <h4
                v-html="item.title"
                class="font-bold text-xl sm:text-2xl text-black mb-3 dark:text-white"
              ></h4>
              <p
                v-html="item.desc"
                class="text-black text-sm sm:text-base mb-4 dark:text-white text-justify"
              ></p>
              <a
                v-if="item.link"
                :href="item.link"
                target="_blank"
                class="inline-block bg-white text-black border border-black-600 rounded-10px hover:text-white hover:bg-black hover:border-black duration-200 ease-in-out no-underline px-3 py-2 text-sm dark:bg-custom-dark dark:text-white dark:border-white dark:hover:bg-white dark:hover:text-custom-dark text-sm sm:text-base"
              >
                <img
                  src="imgs/opensea.png"
                  alt=""
                  class="mr-2 inline-block w-[20px]"
                />
                DISCOVER
              </a>
              <a
                v-else
                href="javascript:void(0)"
                target="_blank"
                class="inline-block bg-white text-black border border-black-600 rounded-10px hover:text-white hover:bg-black hover:border-black duration-200 ease-in-out no-underline px-3 py-2 text-sm btn-disabled dark:bg-custom-dark dark:text-white dark:border-white dark:hover:bg-white dark:hover:text-custom-dark text-sm sm:text-base"
              >
                <img
                  src="imgs/opensea.png"
                  alt=""
                  class="mr-2 inline-block w-[20px]"
                />
                DISCOVER
              </a>
              <div
                v-if="item.ribbon"
                class="w-40 py-1 uppercase absolute bottom-7 -right-9 -rotate-45 text-center text-xs font-semibold"
                :style="`background-color: ${item.ribbon_color}`"
              >
                {{ item.ribbon }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ====== NFTs Section End -->

  <!-- ====== solutions Section Start -->
  <!--
  <section class="pt-20 lg:pt-[100px]">
    <div class="container px-4 sm:px-8 md:px-12 lg:px-18">
      <div class="text-center mb-16">
        <div class="w-full px-4">
          <div class="">
            <span
              class="font-semibold text-lg sm:text-xl md:text-2xl text-gray-700 mb-2 block uppercase dark:text-gray-400"
            >
              {{ homePage.solutions.miniTitle }}
            </span>
            <h2
              class="text-font-heavy text-2xl sm:text-[30px] md:text-[40px] text-dark mb-4 uppercase dark:text-white"
            >
              {{ homePage.solutions.title }}
            </h2>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        <div
          class="w-full px-4"
          v-for="(item, i) in homePage.solutions.features"
          :key="'solution-feature' + i"
        >
          <div class="mb-12 group">
            <div class="mb-5">
              <img
                :src="'imgs/' + item.img"
                alt=""
                class="w-full dark:invert"
              />
            </div>
            <h4
              class="text-font-bold text-lg sm:text-xl text-dark mb-3 dark:text-white"
            >
              {{ item.title }}
            </h4>
            <p
              class="text-black-700 text-md sm:text-lg mb-5 lg:mb-11 min-h-[120px] dark:text-gray-300 text-justify"
            >
              {{ item.desc }}
            </p>
            <a
              :href="item.link"
              :target="item.target"
              class="font-medium text-sm sm:text-base py-2 duration-200 btn-border-bottom hover:px-2 uppercase dark:text-white"
            >
              Find out more
              <img
                src="imgs/arrow-right.svg"
                alt=""
                class="ml-2 inline-block dark:invert"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>                        -->
  <!-- ====== solutions Section End -->

  <!-- ====== OpenSea Section Start -->
  <!--
  <section class="pt-20">
    <div
      class="container px-12 sm:px-16 md:px-20 py-16 bg-custom-green dark:bg-custom-green-dark rounded-[30px]"
    >
      <div class="flex flex-wrap">
        <div class="w-full lg:w-7/12 px-2 sm:px-4">
          <div class="relative">
            <img
              src="/imgs/home/bubble-outline.svg"
              alt=""
              class="hidden lg:block w-full dark:invert"
            />
            <img
              src="/imgs/home/bubble-outline2.svg"
              alt=""
              class="block lg:hidden w-full dark:invert"
            />
            <p
              v-html="homePage.opensea.text"
              class="text-font-heavy absolute top-0 bottom-[26px] left-0 right-0 text-xs xs:text-lg sm:text-3xl md:text-4xl flex items-center justify-center uppercase px-2 sm:px-5 lg:px-14 dark:text-white"
            ></p>
          </div>
          <div class="mt-3 lg:mt-11 text-center uppercase">
            <a
              v-html="homePage.hero.btn1"
              href="https://www.app.datalatte.com"
              class="inline-block text-custom-1 font-medium py-2 px-4 sm:py-3 sm:px-7 btn-primary mr-2"
            >
             </a>
            <a
              v-html="homePage.hero.btn2"
              href="https://datalattecom.notion.site/datalatte-in-words-d9c32ece3bad41418cc412e9aa58bd43?pvs=4"
              class="inline-block py-2 px-3 sm:py-3 sm:px-6 text-custom-1 btn-outline mt-2 sm-mt-0 mr-2 sm:mr-0"
            >
            </a> 
          </div>
        </div>
        <div class="w-full lg:w-5/12 px-4 mt-2">
          <img src="imgs/home/character.svg" alt="" class="w-9/12 mx-auto" />
        </div>
      </div>
    </div>
  </section>                      -->
  <!-- ====== OpenSea Section End -->

  <!-- ====== Dashboard Section Start -->  
<section class="pt-20">
  <div class="container px-4 sm:px-8 md:px-12 lg:px-18">
    <div class="flex flex-wrap">
      <div class="w-full lg:w-7/12">
        <img
          :src="'imgs/' + homePage.dashboard.img"
          alt=""
          class="transform scale-50"
        />
      </div>
      <div class="w-full lg:w-5/12 flex flex-wrap items-center">
        <div class="px-4 lg:px-0">
          <span
            class="font-semibold text-lg sm:text-xl md:text-2xl text-gray-700 mb-4 block dark:text-gray-400"
          >
            {{ homePage.dashboard.miniTitle }}
          </span>
          <h2
            v-html="homePage.dashboard.title"
            class="text-font-heavy text-l text-dark mb-5 uppercase dark:text-white"
          ></h2>
          <p
            class="text-black-700 text-md sm:text-lg mb-5 lg:mb-11 dark:text-gray-300 text-justify"
          >
            {{ homePage.dashboard.desc }}
          </p>
          <a
            :href="homePage.dashboard.link"
            class="font-medium text-base py-2 duration-200 btn-border-bottom hover:px-2 uppercase dark:text-white"
          >
            Personalize Your Networking
            <img
              src="imgs/arrow-right.svg"
              alt=""
              class="ml-2 inline-block"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ====== Dashboard Section End -->


  <!-- ====== Qoute Section Start -->
  <section class="pt-20 px-6 sm:px-0 pb-20">
    <div
      class="container px-8 xs:px-10 sm:px-14 md:px-18 lg:px-20 py-10 bg-white rounded-[30px] qoute-box-borders dark:bg-custom-dark"
    >
      <div class="flex flex-wrap items-center justify-center">
        <div class="w-full text-center mb-5">
          <img
            src="imgs/qoute.svg"
            alt=""
            class="mx-auto w-[45px] inline-block dark:invert"
          />
        </div>
        <h3
          class="text-font-italic text-xl sm:text-3xl text-center w-10/12 mb-6 dark:text-white"
        >
          {{ homePage.qoute.text }}
        </h3>
        <p
          v-html="homePage.qoute.from"
          class="w-full text-center text-md sm:text-lg font-bold uppercase dark:text-white"
        ></p>
      </div>
    </div>
  </section>
  <!-- ====== Qoute Section End -->

  <!-- ====== Blog Section Start -->
  <!-- <section class="mt-32 bg-custom-orange dark:bg-custom-dark">
    <div class="container px-8 sm:px-12 md:px-16 pt-10 pb-16">
      <div class="text-center mb-20">
        <div class="w-full px-4">
          <div class="">
            <span
              class="font-semibold text-lg sm:text-xl md:text-2xl text-gray-700 mb-2 block uppercase dark:text-gray-400"
            >
              {{ homePage.blog.miniTitle }}
            </span>
            <h2
              v-html="homePage.blog.title"
              class="text-font-heavy text-2xl sm:text-[30px] md:text-[40px] text-dark mb-4 uppercase dark:text-white"
            ></h2>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        <div
          class="w-full"
          v-for="(item, i) in homePage.blog.blog"
          :key="'blog' + i"
        >
          <div
            class="bg-transparent border border-black rounded-[28px] mb-4 md:mb-0 overflow-hidden dark:border-custom-orange"
          >
            <div
              class="card-image-container"
              :style="`background-image: url(../imgs/${item.img})`"
            >
              <img src="/imgs/1920_1080.png" alt="" class="w-full opacity-0" />
            </div>
            <div class="px-5 sm:px-9 pb-7 pt-6">
              <h4
                v-html="item.title"
                class="text-font-bold text-lg sm:text-xl md:text-2xl md:min-h-[130px] lg:min-h-[160px] xl:min-h-fit text-black mb-3 uppercase dark:text-white"
              ></h4>
              <p
                v-html="item.desc"
                class="text-black mb-5 min-h-[100px] dark:text-gray-300 text-sm sm:text-base text-justify"
              ></p>

              <a
                :href="item.link"
                target="_blank"
                class="font-medium text-sm sm:text-base py-2 duration-200 btn-border-bottom hover:px-2 uppercase dark:text-white"
              >
                Read
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-14">
        <a
          :href="homePage.blog.more"
          target="_blank"
          class="font-medium text-base py-2 duration-200 btn-border-bottom hover:px-2 uppercase dark:text-white"
        >
          More ARTICLES
          <img
            src="imgs/arrow-right.svg"
            alt=""
            class="ml-2 inline-block dark:invert"
          />
        </a>
      </div>
    </div>
  </section>  -->
  <!-- ====== Blog Section End -->

  <!-- ====== reviews Start ====== -->
  <!--
  <section id="testimonials" class="pt-20 md:pt-[120px] hidden">
    <div class="container px-8 sm:px-8 md:px-12 lg:px-16">
      <div class="text-center mb-16">
        <div class="w-full px-4">
          <div class="">
            <span
              class="font-semibold text-lg sm:text-xl md:text-2xl text-gray-700 mb-2 block uppercase dark:text-gray-400"
            >
              {{ homePage.reviews.miniTitle }}
            </span>
            <h2
              v-html="homePage.reviews.title"
              class="text-font-heavy text-2xl sm:text-[30px] md:text-[40px] text-dark mb-4 uppercase dark:text-white"
            ></h2>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap justify-center">
        <div
          class="w-full md:w-1/2 lg:w-1/3 px-2"
          v-for="(item, i) in homePage.reviews.reviews"
          :key="'review' + i"
        >
          <div class="p-8 bg-white mb-4 border border-black rounded-2xl">
            <div class="mb-6">
              <p class="text-base tracking-wide text-black-700">
                {{ item.text }}
              </p>
            </div>
            <div class="flex items-center justify-between">
              <div class="">
                <h4 class="text-sm font-semibold">{{ item.name }}</h4>
                <p class="text-[#969696] text-xs"></p>
              </div>

              <div class="flex items-center">
                <span
                  class="text-[#6DE4AE] mr-1"
                  v-for="i in item.rank"
                  :key="'rank' + i"
                >
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 0.2 0.19"
                    class="fill-current"
                  >
                    <path
                      class="fil0"
                      d="M0.09 0.01c0,-0.01 0.02,-0.01 0.03,0l0.01 0.04c0,0.01 0.01,0.01 0.01,0.01l0.05 0c0.01,0 0.02,0.02 0,0.03l-0.03 0.02c-0.01,0.01 -0.01,0.02 -0.01,0.02l0.02 0.04c0,0.02 -0.01,0.03 -0.03,0.02l-0.03 -0.03c-0.01,0 -0.01,0 -0.02,0l-0.03 0.03c-0.02,0.01 -0.03,0 -0.03,-0.02l0.02 -0.04c0,0 0,-0.01 -0.01,-0.02l-0.03 -0.02c-0.02,-0.01 -0.01,-0.03 0.01,-0.03l0.04 0c0,0 0.01,0 0.01,-0.01l0.02 -0.04z"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>                     -->
  <!-- ====== reviews End ====== -->

  <!-- ====== partners Section Start -->
   <section class="pt-20 lg:pt-[120px] pb-8 lg:pb-[70px]">
    <div class="container px-8 sm:px-12 md:px-16 lg:px-18">
      <div class="text-center mb-20">
        <div class="w-full px-4">
          <div class="">
            <span
              class="font-semibold text-lg sm:text-xl md:text-2xl text-gray-700 mb-2 block uppercase dark:text-gray-400"
            >
              {{ homePage.partners.miniTitle }}
            </span>
            <h2
              v-html="homePage.partners.title"
              class="text-font-heavy text-2xl sm:text-[30px] md:text-[40px] text-dark mb-4 uppercase dark:text-white"
            ></h2>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 md:grid-cols-3">
        <div
          class="w-full"
          v-for="(item, i) in homePage.partners.partners"
          :key="'partner' + i"
        >
          <div class="mb-12 group">
            <div class="mb-5">
              <img
                :src="'/imgs/' + item.img"
                alt="partners"
                class="w-full scale-105 dark:hidden"
              />
              <img
                :src="'/imgs/' + item.img_dark"
                alt="partners"
                class="w-full scale-105 hidden dark:block"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
    <!-- ====== TEAM Start ====== -->
  <section
    id="team"
    class="py-20 md:py-[120px] mt-12 bg-custom-purple dark:bg-custom-purple-dark"
  >
    <div class="container">
      <div class="text-center mb-16">
        <div class="w-full px-4">
          <div class="">
            <span
              class="font-semibold text-xl sm:text-2xl text-gray-700 mb-2 block uppercase dark:text-gray-300"
            >
              {{ aboutPage.team.miniTitle }}
            </span>
            <h2
              class="text-font-heavy text-xl sm:text-2xl md:text-3xl md:text-[40px] text-dark mb-4 uppercase dark:text-white"
              v-html="aboutPage.team.title"
            ></h2>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap justify-center">
        <div
          class="w-full md:w-1/2 lg:w-1/3 px-3 md:px-2"
          v-for="(item, i) in aboutPage.team.team"
          :key="'team' + i"
        >
          <div
            class="bg-custom-purple mb-4 mx-2 md:mx-0 border border-black rounded-2xl overflow-hidden about-user-card about-user-info cursor-pointer group dark:bg-custom-purple-dark dark:border-white"
          >
            <div class="flex">
              <div
                class="w-[105px] h-[140px] xs:w-[150px] xs:h-[200px] relative bg-contain border-r border-black flex dark:border-white"
                style="background-image: url('../imgs/about/team/pattern2.png')"
              >
                <img
                  :src="`/imgs/${item.img2}`"
                  alt=""
                  class="m-0 w-full opacity-0 group-hover:opacity-100 duration-200 absolute self-center"
                />
                <img
                  :src="`/imgs/${item.img}`"
                  alt=""
                  class="m-0 w-full h-full opacity-100 group-hover:opacity-0 duration-200"
                />
              </div>
              <div class="w-6/12 sm:w-7/12 pl-4">
                <div class="table h-full relative">
                  <div
                    class="table-cell pt-2 sm:pt-0 sm:align-middle dark:text-white"
                  >
                    <h4
                      class="text-font-bold text-md xs:text-lg sm:text-xl w-full"
                      v-html="item.name"
                    ></h4>
                    <p class="w-full text-xs xs:text-sm sm:text-base">
                      {{ item.title }}
                    </p>
                  </div>
                  <div
                    class="absolute bottom-[5px] left-0 opacity-0 group-hover:opacity-100 duration-200"
                  >
                    <span v-if="item.link">
                      <a
                        v-for="link in item.link"
                        :key="link"
                        :href="link.link"
                        target="_blank"
                        class="inline-block mr-1 hover:scale-105 duration-200 border border-black-900 p-[3px] rounded-lg w-[30px] h-[30px] dark:invert"
                        @click="event.stopPropagation()"
                      >
                        <svg
                          v-if="link.website == 'github'"
                          viewBox="0 0 20.06 19.57"
                          class="w-full h-full"
                        >
                          <g id="Layer_x0020_1">
                            <path
                              class="fil0"
                              d="M10.03 0c-5.54,0 -10.03,4.49 -10.03,10.03 0,4.43 2.87,8.19 6.86,9.52 0.5,0.09 0.69,-0.22 0.69,-0.48 0,-0.24 -0.01,-0.87 -0.02,-1.71 -2.79,0.61 -3.38,-1.34 -3.38,-1.34 -0.45,-1.16 -1.11,-1.47 -1.11,-1.47 -0.91,-0.62 0.07,-0.61 0.07,-0.61 1,0.07 1.53,1.03 1.53,1.03 0.9,1.54 2.35,1.09 2.92,0.84 0.09,-0.65 0.35,-1.09 0.64,-1.34 -2.23,-0.26 -4.57,-1.12 -4.57,-4.96 0,-1.1 0.39,-1.99 1.03,-2.69 -0.1,-0.26 -0.44,-1.28 0.1,-2.66 0,0 0.84,-0.27 2.76,1.03 0.8,-0.22 1.66,-0.33 2.51,-0.34 0.85,0.01 1.71,0.12 2.51,0.34 1.92,-1.3 2.76,-1.03 2.76,-1.03 0.55,1.38 0.2,2.4 0.1,2.66 0.64,0.7 1.03,1.59 1.03,2.69 0,3.85 -2.35,4.7 -4.58,4.95 0.36,0.31 0.68,0.92 0.68,1.86 0,1.34 -0.01,2.42 -0.01,2.75 0,0.27 0.18,0.58 0.69,0.48 3.98,-1.33 6.85,-5.09 6.85,-9.52 0,-5.54 -4.49,-10.03 -10.03,-10.03z"
                            />
                          </g>
                        </svg>
                        <svg
                          v-else-if="link.website == 'twitter'"
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z"
                          />
                        </svg>
                        <svg
                          v-else-if="link.website == 'farcaster'"
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M18.24.24H5.76A5.76 5.76 0 0 0 0 6v12a5.76 5.76 0 0 0 5.76 5.76h12.48A5.76 5.76 0 0 0 24 18V6A5.76 5.76 0 0 0 18.24.24m.816 17.166v.504a.49.49 0 0 1 .543.48v.568h-5.143v-.569A.49.49 0 0 1 15 17.91v-.504c0-.22.153-.402.358-.458l-.01-4.364c-.158-1.737-1.64-3.098-3.443-3.098c-1.804 0-3.285 1.361-3.443 3.098l-.01 4.358c.228.042.532.208.54.464v.504a.49.49 0 0 1 .543.48v.568H4.392v-.569a.49.49 0 0 1 .543-.479v-.504c0-.253.201-.454.454-.472V9.039h-.49l-.61-2.031H6.93V5.042h9.95v1.966h2.822l-.61 2.03h-.49v7.896c.252.017.453.22.453.472"
                          />
                        </svg>
                        <svg
                          v-else
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          class="w-full h-full p-[2px]"
                        >
                          <path
                            d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"
                          />
                        </svg>
                        <!-- <svg width="18" height="18" viewBox="0 0 18 18" class="fill-current w-full h-full">
                                            <path d="M16.7821 0.947388H1.84847C1.14272 0.947388 0.578125 1.49747 0.578125 2.18508V16.7623C0.578125 17.4224 1.14272 18 1.84847 18H16.7257C17.4314 18 17.996 17.4499 17.996 16.7623V2.15757C18.0525 1.49747 17.4879 0.947388 16.7821 0.947388ZM5.7442 15.4421H3.17528V7.32837H5.7442V15.4421ZM4.44563 6.2007C3.59873 6.2007 2.94944 5.5406 2.94944 4.74297C2.94944 3.94535 3.62696 3.28525 4.44563 3.28525C5.26429 3.28525 5.94181 3.94535 5.94181 4.74297C5.94181 5.5406 5.32075 6.2007 4.44563 6.2007ZM15.4835 15.4421H12.9146V11.509C12.9146 10.5739 12.8864 9.33618 11.5596 9.33618C10.2045 9.33618 10.0069 10.3813 10.0069 11.4265V15.4421H7.438V7.32837H9.95046V8.45605H9.9787C10.3457 7.79594 11.1644 7.13584 12.4347 7.13584C15.0601 7.13584 15.54 8.7861 15.54 11.0414V15.4421H15.4835Z" />
                                        </svg> -->
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="about-user-content border-t border-black p-3 bg-[#fff] dark:border-white text-justify"
              style="display: none"
              v-html="item.desc"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ====== TEAM End ====== -->
</template>

<script setup>
import homePage from "@/assets/data/homePage.json";
import aboutPage from "@/assets/data/aboutPage.json";
const { onMounted } = require("@vue/runtime-core");

onMounted(() => {
  $(".about-user-card").click(function () {
    if ($(this).find(".about-user-content")[0].style.display == "none") {
      $(".about-user-content").slideUp();
      $(".about-user-card").removeClass("z-[4]");
      $(".about-user-card img").parent().css("transform", "");
      $(".about-user-card").removeClass("bg-[#fff]");
      // $('.about-user-card').find('.about-user-info').removeClass('bg-[#fff]');
      $(this).addClass("z-[4]");
      $(this).addClass("bg-[#fff]");
      // $(this).find('.about-user-info').addClass('bg-[#fff]');
      $(this).find(".about-user-content").slideDown();
      // $(this).find('img').parent().css("transition", "0.3s").css("transform", "scale(1.2)");
    } else {
      var dis = $(this);
      $(this)
        .find(".about-user-content")
        .slideUp(400, function () {
          dis.removeClass("z-[4]");
          dis.removeClass("bg-[#fff]");
          // dis.find('.about-user-info').removeClass('bg-[#fff]');
          dis.find("img").parent().css("transform", "");
        });
    }
    // console.log($(this).find('.about-user-content')[0].style.display);
    // $(this).toggleClass('zindex-1');
    // $(this).find('.about-user-content').slideToggle();
  });
  // $('.about-user-card').hover(function () {
  //     // $('.about-user-content').slideUp();
  //     $(this).addClass('zindex-1');
  //     $(this).find('.about-user-content').slideDown();
  // }, function () {
  //     var dis = $(this);
  //     $(this).find('.about-user-content').slideUp(400, function () {
  //         // Animation complete.
  //         dis.removeClass('zindex-1');
  //     });
  // });
});

</script>

<style></style>
