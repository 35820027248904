<template>
  <!-- ====== Hero Section Start -->
  <!-- <div id="hero" class="flex items-center bg-custom-orange h-auto pt-16 pb-6 lg:pt-14 hero-bg">
    <div class="container px-6 lg:px-0">
        <div class="px-12 lg:px-0 py-36 text-center">
            <img src="/imgs/{{ faqPage.hero.img }}" alt="" class="w-[250px] mx-auto">
            <h3 class="text-font-heavy text-3xl mt-5">{{ faqPage.hero.title }}</h3>
        </div>
    </div>
</div> -->
  <!-- ====== Hero Section End -->

  <!-- ====== Contact Section Start -->
  <section class="pt-36 pb-28">
    <div
      class="container px-8 sm:px-16 md:px-18 lg:px-20 py-16 bg-custom-green rounded-[30px] dark:bg-custom-green-dark"
    >
      <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
        <div
          class="px-0 sm:px-4 rounded-[30px]"
          style="background-color: rgba(0, 0, 0, 0.05)"
        >
          <img
            src="/imgs/contact.svg"
            alt=""
            class="w-10/12 sm:w-9/12 mx-auto dark:hidden"
          />
          <img
            src="/imgs/contact-dark.svg"
            alt=""
            class="w-10/12 sm:w-9/12 mx-auto hidden dark:block"
          />
        </div>
        <div class="px-4 lg:pl-8 py-4 dark:text-white">
          <h2 class="text-font-bold font-bold text-2xl sm:text-3xl mb-5">
            Leave us a message
          </h2>
          <p class="text-sm sm:text-base text-justify">
            We are a community, each opinion, feedback and comment is important
            to us!
          </p>

          <form
            class="mt-3"
            action="mailto:amir@datalatte.com"
            method="get"
            enctype="text/plain"
          >
            <label class="block mb-3">
              <span class="block text-sm font-medium">Subject</span>
              <!-- Using form state modifers, the classes can be identical for every input -->
              <input
                type="text"
                name="subject"
                class="mt-1 block w-full px-3 py-2 bg-transparent border border-black rounded-md text-sm placeholder-slate-400 focus:outline-none focus:border-black focus:ring-1 focus:ring-black invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 dark:border-white dark:focus:border-white dark:focus:ring-white"
              />
            </label>
            <label class="block mb-3">
              <span class="block text-sm font-medium">Message</span>
              <textarea
                name="body"
                class="mt-1 block w-full px-3 py-2 bg-transparent border border-black rounded-md text-sm placeholder-slate-400 focus:outline-none focus:border-black focus:ring-1 focus:ring-black invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 dark:border-white dark:focus:border-white dark:focus:ring-white"
                cols="30"
                rows="5"
              ></textarea>
            </label>
            <div class="">
              <input
                type="submit"
                value="Submit"
                class="text-custom-1 font-medium py-2 px-4 sm:py-3 sm:px-7 btn-primary cursor-pointer"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- ====== Contact Section End -->
</template>
