<template>
  <div
    id="hero"
    class="flex items-center bg-custom-orange min-h-screen h-auto hero-bg dark:bg-custom-orange-dark"
  >
    <div class="container px-6 lg:px-0">
      <div class="px-12 lg:px-0 py-36 text-center">
        <img
          :src="`/imgs/page_not_found.svg`"
          alt=""
          class="w-[400px] mx-auto invert-[0.8] dark:invert-0"
        />
        <h3
          class="text-font-heavy text-xl sm:text-2xl lg:text-3xl mt-10 dark:text-white"
        >
          Page Not Found!
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
