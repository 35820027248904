<template>
  <div
    v-if="
      (withLoader ? !isLoaded.first : false) ||
      (withLoader ? !isLoaded.second : false)
    "
    class="max-w-[459px] w-10/12 sm:w-full mx-auto aspect-square text-center flex justify-center"
  >
    <CircleLoader size="50px" class="aspect-square" />
  </div>
  <div class="relative transition_pos_absolute mx-auto">
    <transition name="fade">
      <img
        v-show="
          modelValue && (withLoader ? isLoaded.first && isLoaded.second : true)
        "
        :src="firstSrc"
        class="mx-auto z-0"
        :class="firstClass"
        @load="onLoadImage('first')"
      />
    </transition>
    <transition name="fade">
      <img
        v-show="
          !modelValue && (withLoader ? isLoaded.first && isLoaded.second : true)
        "
        :src="secondSrc"
        class="mx-auto z-0"
        :class="secondClass"
        @load="onLoadImage('second')"
      />
    </transition>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import CircleLoader from "../circle-loader.vue";

defineProps({
  modelValue: Boolean,
  firstSrc: String,
  firstClass: String,
  secondSrc: String,
  secondClass: String,
  withLoader: {
    type: Boolean,
    default: false,
  },
});

const isLoaded = reactive({
  first: false,
  second: false,
});

const onLoadImage = (type = "first") => {
  isLoaded[type] = true;
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to
/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.transition_pos_absolute > .fade-leave-active {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  /* transition: opacity 0.3s ease; */
}
</style>
