import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";
import routes from "@/router/routes";
import guard from "@/router/guard";

const router = createRouter({
  history:
    process.env.NODE_ENV == "development"
      ? createWebHashHistory()
      : createWebHistory(),
  routes,
});

guard.init(router);

export default router;
