<template>
  <div
    class="text-black bg-white text-left overflow-x-hidden dark:bg-custom-dark h-full"
  >
    <navBar />
    <router-view />
    <totop />
<!--    <experience-button />  -->
    <CustomFooter />
  </div>
</template>

<script setup>
import navBar from "./components/nav.vue";
import CustomFooter from "./components/footer.vue";
import totop from "./components/totop.vue";
import ExperienceButton from "./components/experience-button.vue";
import { onMounted } from "@vue/runtime-core";

onMounted(() => {
  // ======= Sticky
  window.onscroll = function () {
    const ud_header = document?.querySelector(".ud-header");
    const sticky = ud_header.offsetTop;
    // const logo = document?.querySelector(".header-logo");

    if (window.pageYOffset > sticky) {
      ud_header.classList.add("sticky");
    } else {
      ud_header.classList.remove("sticky");
    }

    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    // var _paq = (window._paq = window._paq || []);
    // _paq.push(["setCookieDomain", "*.datalatte.com"]);
    // _paq.push(["trackPageView"]);
    // _paq.push(["enableLinkTracking"]);
    // var u = "https://datalatte.matomo.cloud/";
    // _paq.push(["setTrackerUrl", u + "matomo.php"]);
    // _paq.push(["setSiteId", "1"]);
    // const d = document,
    //   g = d.createElement("script"),
    //   s = d.getElementsByTagName("script")[0];
    // g.async = true;
    // g.src = "//cdn.matomo.cloud/datalatte.matomo.cloud/matomo.js";
    // s.parentNode.insertBefore(g, s);

    // === logo change
    // if (ud_header.classList.contains("sticky")) {
    //     logo.src = "assets/images/logo/datalatte.svg";
    // } else {
    //     logo.src = "assets/images/logo/logo-white.svg";
    // }

    // show or hide the back-top-top button
    const floatings = document?.querySelectorAll(".floating-main-btn");
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      floatings.forEach((btn) => (btn.style.display = "flex"));
    } else {
      floatings.forEach((btn) => (btn.style.display = "none"));
    }
  };

  // ===== responsive navbar
  let navbarToggler = document?.querySelector("#navbarToggler");
  const navbarCollapse = document?.querySelector("#navbarCollapse");

  navbarToggler.addEventListener("click", () => {
    navbarToggler.classList.toggle("navbarTogglerActive");
    navbarCollapse.classList.toggle("hidden");
  });

  //===== close navbar-collapse when a  clicked
  document
    .querySelectorAll("#navbarCollapse ul li:not(.submenu-item) a")
    .forEach((e) =>
      e.addEventListener("click", () => {
        navbarToggler.classList.remove("navbarTogglerActive");
        navbarCollapse.classList.add("hidden");
      })
    );

  // ===== Sub-menu
  const submenuItems = document?.querySelectorAll(".submenu-item");
  submenuItems.forEach((el) => {
    el.querySelector("a").addEventListener("click", () => {
      el.querySelector(".submenu").classList.toggle("hidden");
    });
  });

  // ===== Faq accordion
  const faqs = document?.querySelectorAll(".single-faq");
  faqs.forEach((el) => {
    el.querySelector(".faq-btn").addEventListener("click", () => {
      el.querySelector(".icon").classList.toggle("rotate-180");
      el.querySelector(".faq-content").classList.toggle("hidden");
    });
  });

  // ====== scroll top js
  function scrollTo(element, to = 0, duration = 500) {
    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;

    const animateScroll = () => {
      currentTime += increment;

      const val = Math.easeInOutQuad(currentTime, start, change, duration);

      element.scrollTop = val;

      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };

    animateScroll();
  }

  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  document.querySelector(".back-to-top").onclick = () => {
    scrollTo(document.documentElement);
  };

  function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  // console.log(window.location.pathname);
  if (!isMobile() && window.location.pathname != "/") {
    $("#navbar nav a[href*='" + window.location.pathname + "']")
      .find(".current-page-link")
      .show();
  }

  $("#dark_mode_toggle").click(function () {
    if ($("html").hasClass("dark")) {
      $("html").removeClass("dark");
      localStorage.setItem("darkMode", false);
      $("#dark_mode_toggle").removeClass("dark-mode-on");
    } else {
      $("html").addClass("dark");
      localStorage.setItem("darkMode", true);
      $("#dark_mode_toggle").addClass("dark-mode-on");
    }
  });

  if (localStorage.getItem("darkMode") === null) {
    // Check to see if Media-Queries are supported
  } else {
    if (localStorage.getItem("darkMode") == "true") {
      $("#dark_mode_toggle").trigger("click");
    } else {
    }
  }
});
</script>
