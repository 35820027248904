import Home from "@/views/home.vue";
import About from "@/views/about.vue";
import Contact from "@/views/contact.vue";
import Faq from "@/views/faq.vue";
// import HowItWorks from "@/views/how-it-works.vue";
// import Nft from "@/views/nft.vue";
import Terms from "@/views/terms.vue";
import datalatte2go from "@/views/datalatte2go.vue";

import NotFound from "@/views/404.vue";
import Unsubscribe from "@/views/unsubscribe.vue";

export default [
  {
    name: "Home",
    path: "/",
    component: Home,
  },
  {
    name: "About",
    path: "/about",
    component: About,
    meta: {
      title: "About",
    },
  },
  {
    name: "Contact",
    path: "/contact",
    component: Contact,
    meta: {
      title: "Contact",
    },
  },
  {
    name: "FAQ",
    path: "/faq",
    component: Faq,
    meta: {
      title: "FAQ",
    },
  },
  // {
  //   name: "HowItWorks",
  //   path: "/how-it-works",
  //   component: HowItWorks,
  //   meta: {
  //     title: "How it works",
  //   },
  // },
  // {
  //   name: "NFT",
  //   path: "/nft",
  //   component: Nft,
  //   meta: {
  //     title: "NFT",
  //   },
  // },
  {
    name: "Terms",
    path: "/terms",
    component: Terms,
    meta: {
      title: "Terms & Conditions & Data Policy",
    },
  },
  {
    name: "datalatte2go",
    path: "/datalatte2go",
    component: datalatte2go,
    meta: {
      title: "datalatte2go",
    },
  },
  {
    name: "Unsubscribe",
    path: "/unsubscribe",
    component: Unsubscribe,
    meta: {
      title: "Unsubscribe",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFound,
  },
];
