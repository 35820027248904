<template>
  <div
    class="bubble bubble-bottom-left w-full p-4 md:min-h-[300px] flex items-center"
    :style="`--main-color: ${backgroundColor}`"
  >
    <span
      v-if="withBackBtn"
      id="expand-icon"
      class="inline-block w-3 h-3 border-t-2 border-l-2 mb-1 border-[grey] rotate-[315deg] dark:border-white cursor-pointer"
      @click="$emit('onBackPressed')"
    ></span>
    <div class="flex flex-1 justify-center">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      default: "green",
    },
    withBackBtn: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.bubble {
  border: 1px solid #555;
  border-radius: 2.5rem;
  text-align: center;
  position: relative;
  background-color: var(--main-color);
}
.bubble-bottom-left:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid #555;
  border-right: 20px solid transparent;
  border-top: 20px solid #555;
  border-bottom: 20px solid transparent;
  left: 30px;
  bottom: -40px;
}

.bubble-bottom-left:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 21px solid var(--main-color);
  border-right: 21px solid transparent;
  border-top: 21px solid var(--main-color);
  border-bottom: 21px solid transparent;
  left: 31px;
  bottom: -38px;
}
</style>
