import { BASE_URL } from "@/constants/general";
import axios from "axios";
import Web3 from "web3/dist/web3.min.js";

const web3 = new Web3(window.ethereum);

const getSignKey = (walletId) => {
  return axios.post(
    `${BASE_URL}/login`,
    { wallet_id: walletId },
    {
      headers: {
        "name-space": "experience-latte",
      },
    }
  );
};

export const getWalletId = () => {
  const ethereumRequestConfig = {
    method: "eth_requestAccounts",
  };
  return window.ethereum.request(ethereumRequestConfig);
};

const signTransaction = ({ signKey, from }) => {
  const signConfig = {
    method: "eth_signTypedData",
    params: [signKey, from],
    from,
  };

  return new Promise((resolve, reject) => {
    web3.currentProvider.sendAsync(signConfig, (error, response) => {
      if (error) reject(error);
      resolve(response);
    });
  });
};

const getToken = ({ walletId, signature }) => {
  return axios.post(
    `${BASE_URL}/verifysign`,
    {
      wallet_id: walletId,
      signature: signature.result,
    },
    {
      headers: {
        "name-space": "experience-latte",
      },
    }
  );
};

export const login = async () => {
  const [walletId] = await getWalletId();
  const signKey = await getSignKey(walletId);
  const signature = await signTransaction({
    signKey: signKey.data?.data || [],
    from: walletId,
  });
  const token = await getToken({ walletId, signature });

  return { walletId, token: token.data?.data?.token };
};

export default {
  getSignKey,
  getWalletId,
  signTransaction,
  getToken,
  login,
};
