<template>
  <span
    class="str text-[8px] sm:text-[9px] md:text-xs lg:text-sm text-font-thin inline-block pr-1 max-w-fit"
    :class="['str' + index, shouldStart && !finished && 'blinking']"
  ></span>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";

const props = defineProps({
  text: String,
  index: Number,
  completedIndex: Number,
  shouldStart: Boolean,
  // step: Number,
});

// const isPlaying = ref(false);

const emit = defineEmits(["onComplete"]);

const finished = ref(false);

const typeSpeed = 50;

var running, nextDelay;

// const initTypeWriterAudio = () => {
//   let audio = new Audio("/typewriter.mp3");
//   audio.onplay = () => {
//     isPlaying.value = true;
//   };
//   audio.onended = () => {
//     isPlaying.value = false;
//   };
// };

const showText = () => {
  if (props.completedIndex > props.index) {
    if (running) clearTimeout(running);
    if (nextDelay) clearTimeout(nextDelay);
    let el = document.getElementsByClassName("str" + props.index)[0];
    el.innerHTML = props.text;
    finished.value = true;
  } else if (props.shouldStart && !finished.value) {
    let str = props.text.split("");
    let el = document.getElementsByClassName("str" + props.index)[0];
    // initTypeWriterAudio();

    const animate = () => {
      // if (props.step > 0 && !isPlaying.value && audio?.play && document.hasFocus())
      //   audio?.play().catch((error) => {});
      if (str.length) el.innerHTML += str.shift();
      else {
        clearTimeout(running);
        nextDelay = setTimeout(() => {
          if (props.completedIndex == props.index)
            emit("onComplete", props.index);
          finished.value = true;

          // audio.pause();
        }, 500);
      }
      running = setTimeout(animate, typeSpeed);
    };

    animate();
  }
};

onMounted(() => {
  showText();
});

watch(props, () => {
  showText();
});
</script>

<style scoped>
.str {
  color: #fff;
  font-weight: bold;
}

.blinking {
  border-right-width: 5px;
  -webkit-animation: blinking 0.5s step-end infinite;
  animation: blinking 0.5s step-end infinite;
}

@keyframes blinking {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
}
</style>
