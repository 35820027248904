<template>
  <div class="h-[210px] sm:h-[160px]">
    <VueFlip v-model="flipped" height="100%" width="100%">
      <template #front>
        <slot name="front" />
      </template>
      <template #back>
        <slot name="back" />
      </template>
    </VueFlip>
  </div>
  <div class="section" v-if="!disabled">
    <input
      class="pricing"
      type="checkbox"
      id="pricing"
      name="pricing"
      @click="flipped = !flipped"
    />
    <label for="pricing">
      <span class="block-diff text-font-bold px-[40px]" :class="titlesClass">
        <span class="float-left z-50"> {{ frontTitle }} </span>
        <span class="float-right z-50"> {{ backTitle }} </span>
      </span>
    </label>
  </div>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { VueFlip } from "vue-flip";

defineProps({
  frontTitle: String,
  backTitle: String,
  titlesClass: String,
  disabled: {
    type: Boolean,
    default: false,
  },
});

const flipped = ref(false);
</script>

<style scoped>
.section {
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column-reverse;
}
[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.pricing:checked + label,
.pricing:not(:checked) + label {
  position: relative;
  display: block;
  text-align: center;
  width: 260px;
  height: 44px;
  border-radius: 10px;
  padding: 0;
  margin: 0 auto;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 44px;
  overflow: hidden;
  color: black;
  text-align: left;
}
.pricing:checked + label:before,
.pricing:not(:checked) + label:before {
  position: absolute;
  content: "";
  z-index: -2;
  background-color: #ffffffa0;
  backdrop-filter: blur(12px);
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 10px;
  top: 0;
  left: 0;
}
.pricing:checked + label:after,
.pricing:not(:checked) + label:after {
  position: absolute;
  content: "";
  z-index: -1;
  background-color: #93b9fa80;
  width: 128px;
  height: 40px;
  display: block;
  top: 2px;
  left: 2px;
  border-radius: 10px;
  -webkit-transition: left 200ms linear;
  transition: left 200ms linear;
}
.pricing:checked + label:after {
  left: 130px;
}
.block-diff {
  display: block;
}
</style>
