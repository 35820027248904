<template>
  <div class="bar flex relative">
    <div class="red"></div>
    <div class="yellow"></div>
    <div class="green"></div>
    <div class="header-text relative">
      <div class="mx-auto w-fit -translate-x-1/2">datalatte</div>
    </div>
    <div class="mr-4">
      <span
        id="expand-icon"
        class="inline-block w-3 h-3 border-t-2 border-l-2 mb-1 border-black rotate-[225deg] my-auto dark:border-white cursor-pointer"
        @click="toggleTerminal"
      ></span>
    </div>
  </div>
  <div
    class="d-flex grid grid-cols bg-black text-white px-2 pb-1"
    style="--cols: 10"
  >
    <div
      class="text-font-bold text-[10px] sm:text-[12px] md:text-sm lg:text-base col-span-6 xs:col-span-4 sm:col-span-3"
    >
      survey_input_log
    </div>
    <div
      class="text-font-bold text-[10px] sm:text-[12px] md:text-sm lg:text-base col-span-2 xs:col-span-4 sm:col-span-5 pr-2 text-ellipsis overflow-hidden"
    >
      contract_(testnet)
    </div>
    <div
      class="text-font-bold text-[10px] sm:text-[12px] md:text-sm lg:text-base col-span-2"
    >
      XPs
    </div>
  </div>
  <div
    ref="experienceTerminal"
    id="experienceTerminal"
    class="w-full overflow-hidden bg-black terminal overflow-auto pb-[10px] h-[45px] sm:h-[50px] md:h-[60px] lg:h-[70px] transition-height ease-in-out px-2"
    :class="'duration-' + animationDuration"
  >
    <div class="grid grid-cols gap-0" style="--cols: 10">
      <Typewriter
        v-for="(item, i) in items"
        :key="'terminal' + i"
        :index="i + 1"
        :text="item"
        :completedIndex="completedIndex"
        :shouldStart="completedIndex == i + 1"
        @onComplete="increase"
        :class="
          (i + 1) % columns == 0
            ? 'col-span-2'
            : (i + 1) % columns == 2
            ? 'col-span-2 xs:col-span-4 sm:col-span-5 text-ellipsis overflow-hidden pr-4'
            : 'col-span-6 xs:col-span-4 sm:col-span-3 text-ellipsis overflow-hidden pr-4'
        "
      />
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "@vue/reactivity";
import { nextTick, onMounted, watch } from "@vue/runtime-core";
import Typewriter from "./typewriter.vue";

const props = defineProps({
  step: Number,
});

const columns = 3;
const animationDuration = 300;

const experienceTerminal = ref(null);

const isExpanded = ref(true);
const completedIndex = ref(6);

const contract = ref("0xEa84D1b6c04eB5F0ab9Ac7147312414828F1DFbc");

const items = reactive([
  "> start intro",
  "-",
  "-",
  "> start intro 2",
  "-",
  "-",
]);

onMounted(() => {
  nextTick(() => {
    increase();
  });
});

const addItems = (arr) => {
  if (!items.includes(arr[0])) {
    items.push(arr[0], contract.value, arr[2]);
    completedIndex.value = (props.step + 1) * columns + 1;

    scrollToBottom();
    // if (completedIndex.value <= items.length) scrollToBottom();
  }
};

const deleteItems = (count) => {
  if (items.length > columns * 2) {
    items.splice(items.length - count, count);
    completedIndex.value = items.length + 1;

    scrollToBottom();
  }
};

defineExpose({ addItems, deleteItems });

const scrollToBottom = () => {
  $("#experienceTerminal").animate({
    scrollTop: $("#experienceTerminal").prop("scrollHeight"),
  });
};

const increase = () => {
  completedIndex.value += 1;
  if (completedIndex.value <= items.length) scrollToBottom();
};

const toggleTerminal = () => {
  const expandIcon = document.querySelector("#expand-icon");
  const experienceTerminal = document.querySelector("#experienceTerminal");
  experienceTerminal.classList.toggle("h-[23px]");
  experienceTerminal.classList.toggle("sm:h-[25px]");
  experienceTerminal.classList.toggle("md:h-[28px]");

  experienceTerminal.classList.toggle("h-[45px]");
  experienceTerminal.classList.toggle("sm:h-[50px]");
  experienceTerminal.classList.toggle("md:h-[60px]");
  experienceTerminal.classList.toggle("lg:h-[70px]");

  expandIcon.classList.toggle("rotate-[225deg]");
  expandIcon.classList.toggle("rotate-[45deg]");
  expandIcon.classList.toggle("mt-1");
  expandIcon.classList.toggle("mb-1");

  isExpanded.value = !isExpanded.value;

  $("#experienceTerminal").animate({
    scrollTop: $("#experienceTerminal").prop("scrollHeight"),
  });
};

watch(items, () => {
  if (completedIndex.value >= items.length) {
    scrollToBottom();
    completedIndex.value = items.length - 2;
  }
});
</script>

<style>
.grid-cols {
  grid-template-columns: repeat(var(--cols), minmax(0, 1fr));
  grid-template-rows: auto 1fr auto;
}

.bar {
  width: 100%;
  height: 25px;
  background-color: #dad9d9;
  margin: 0 auto;
  padding: auto;
  float: none;
}

.red {
  background-color: #e94b35;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  margin: auto;
  margin-left: 10px;
  position: relative;
}

.yellow {
  background-color: #f0f000;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  margin: auto;
  margin-left: 10px;
  position: relative;
}

.green {
  background-color: #1aaf5c;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  margin: auto;
  margin-left: 10px;
  position: relative;
}

.header-text {
  flex: 1;
}
</style>
