import { nextTick } from "vue";
// import persistence from "@/services/persistence";
// import { AUTHENTICATION_ROUTE_NAME } from "@/constants/navigation";

// const beforeEachRoute = (to) => {
//   // const authenticated = !!persistence.retrieve("token", "cookie");
//   // if (to.name === AUTHENTICATION_ROUTE_NAME) {
//   //   return authenticated ? { name: "panel" } : true;
//   // }
//   // const needAuthentication = to.meta.protected;
//   // if (needAuthentication)
//   //   return authenticated ? true : { name: AUTHENTICATION_ROUTE_NAME };
// };

const DEFAULT_TITLE = "datalatte";
const init = (router) => {
  router.afterEach((to, from) => {
    window.setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
    
    nextTick(() => {
      document.title = to.meta.title
        ? to.meta.title + " | datalatte"
        : DEFAULT_TITLE;
    });
  });
};

export default { init };
