<template>
  <div
    class="lg:flex-1 w-full self-center custom-border p-6 duration-200 ease-in-out"
    :class="[
      disabled ? 'border-slate-400' : 'border-black',
      'bg-[' + background + ']',
    ]"
  >
    <div
      class="text-md sm:text-xl text-font-grand"
      :class="disabled ? 'text-[gray]' : 'text-black'"
    >
      Connect Your Wallet To Claim NFT:
    </div>
    <div class="sm:flex block mt-2">
      <Spacer />
      <base-button
        :disabled="disabled"
        color="#93B9FA"
        :loading="!!loading || !!localLoading"
        btnClass="mt-2 sm:mt-0 sm:w-6/12"
        @onClick="loginMetaMask"
      >
        <div :class="disabled ? 'text-[gray]' : 'text-black'">
          Log in using Metamask
        </div>
      </base-button>
    </div>
  </div>
</template>

<script setup>
import Spacer from "@/components/spacer.vue";
import BaseButton from "../../../base-button.vue";
import { getWalletId } from "@/constants/authentication";
import { ref } from "@vue/reactivity";
import { useToast } from "vue-toastification";

defineProps({
  modelValue: String,
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  background: String,
});

const emits = defineEmits(["update:modelValue", "mint"]);

const toast = useToast();

const localLoading = ref(false);

// const metaMaskErrorContent = (
//   <div>
//     <span>
//       You don't have Metamask installed, please follow{" "}
//       <b class="underline">
//         <a
//           href="https://blog.datalatte.com/how-to-install-metamask-browser-extension-61d193a0b8cf"
//           target="_blank"
//         >
//           this link
//         </a>
//       </b>
//       's instructions on how to install it.
//     </span>
//   </div>
// );

const loginMetaMask = async () => {
  localLoading.value = true;
  try {
    const [walletId] = await getWalletId();
    emits("update:modelValue", walletId);
    emits("mint");
  } catch (error) {
    if (typeof window.ethereum === "undefined") {
      toast.error("You don't have Metamask installed", {
        timeout: 10000,
      });
    } else {
      console.log(error);
      toast.error("Something went wrong! Please try again.");
    }
  } finally {
    localLoading.value = false;
  }
};
</script>

<style scoped>
.custom-border {
  border-width: 1px;
  border-style: solid;
  border-radius: 1rem;
}
.disabled {
  color: grey;
  border-color: grey;
}
</style>
