const time = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/;
const number = /^-?\d+(?:\.\d+)?$/;
const english = /^[a-zA-Z0-9?><;,{}[\]\-_+=!@#$%\^&*|'\s]*$/;
const email =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const regex = {
  time,
  number,
  english,
  email,
};

export const VValidation = {
  required: (v) =>
    (v != null &&
      !Array.isArray(v) &&
      !(typeof v == "object") &&
      !(typeof v == "string")) ||
    (Array.isArray(v) && !!v.length) ||
    (typeof v == "string" && !!v.length) ||
    (v != null && typeof v == "object" && !!Object.keys(v).length) ||
    "Required",
  number: (v) => !v || v?.length == 0 || number.test(v) || "Should be number",
  numberRange: (low, high) => (v) =>
    !v ||
    (+v >= low && +v <= high) ||
    "Should be greater than " + low + " And smaller than " + high,
  email: (v) =>
    v == null || v.length <= 0 || email.test(v) || "Email is'nt correct.",
  fixed_length: (length) => (v) =>
    !v ||
    (typeof v == "string" && (v?.length == length || v?.length == 0)) ||
    (typeof v == "number" &&
      (v?.toString()?.length == length || v?.toString()?.length == 0)) ||
    "Should has " + length + " length",
};

export default { VValidation, regex };
