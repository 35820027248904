<template>
  <!-- ====== Back To Top Start -->
  <a
    href="javascript:void(0)"
    class="hidden items-center justify-center bg-primary text-white dark:bg-[white] dark:text-black w-10 h-10 rounded-md fixed bottom-3 right-3 left-auto z-[999] hover:bg-dark floating-main-btn back-to-top shadow-md transition duration-300 ease-in-out"
  >
    <span
      class="w-3 h-3 border-t border-l border-white dark:border-black rotate-45 mt-[6px]"
    ></span>
  </a>
  <!-- ====== Back To Top End -->
</template>

<script>
export default {};
</script>

<style></style>
