<template>
  <Modal :visible="visible">
    <div
      class="w-full h-full flex flex-col items-center overflow-y-auto background-animation bg-[url('../../../public/imgs/stars.svg')]"
      :style="`background-color: ${homePage.experience[step].background};`"
    >
      <div
        id="terminal-container"
        class="w-full h-0 transition scale-y-0 ease-in-out duration-500 z-10"
      >
        <Terminal ref="terminalRef" :step="step" />
      </div>
      <Rate v-if="step <= 5" :modelValue="step" :count="5" class="my-2" />
      <div
        id="experience-content"
        class="container lg:w-7/12 xl:w-6/12 my-auto py-2"
      >
        <template v-if="localStep.title">
          <image-changer
            v-if="localStep.with_data_safety_img"
            @click="dataSafety = !dataSafety"
            :modelValue="dataSafety"
            firstSrc="/imgs/data_safe_hidden.svg"
            firstClass="w-6/12 cursor-pointer"
            secondSrc="/imgs/data_safe_visible.svg"
            secondClass="w-6/12 mb-1 cursor-pointer"
          />
          <div class="flex flex-1 self-center mx-2">
            <bubble-cloud
              :backgroundColor="homePage.experience[step].background"
              :withBackBtn="step > 0"
              @onBackPressed="previousStep"
              class="mb-2"
            >
              <div class="w-10/12 text-left">
                <div
                  class="text-black mb-1 text-font-grand"
                  v-html="localStep.title"
                ></div>
                <ul
                  v-if="localStep.features"
                  class="font-semibold list-style-sparke mx-4"
                >
                  <li
                    v-for="(item, i) in localStep.features"
                    :key="'features' + i"
                    class="text-font-grand text-md sm:text-lg md:text-xl my-6"
                    v-html="item"
                  ></li>
                </ul>
                <div class="mt-8 lg:mt-11 text-center flex">
                  <a
                    v-if="localStep.not_mint_button"
                    class="flex flex-1 items-center justify-center cursor-pointer text-custom-1 py-4 px-6 md:py-6 md:px-14 btn-outline-black mr-1 sm:mr-2"
                    @click="toggleMinting"
                  >
                    <div
                      class="text-[0.6rem] xs:text-[0.7rem] sm:text-base md:text-md"
                    >
                      {{ localStep.not_mint_button }}
                    </div>
                  </a>
                  <a
                    v-if="localStep.back_button"
                    class="flex flex-1 items-center justify-center cursor-pointer text-custom-1 py-4 px-6 md:py-6 md:px-14 btn-outline-black mr-1 sm:mr-2"
                    @click="previousStep"
                  >
                    <div
                      class="text-[0.6rem] xs:text-[0.7rem] sm:text-base md:text-md"
                    >
                      {{ localStep.back_button }}
                    </div>
                  </a>
                  <a
                    v-if="localStep.exit_button"
                    class="flex flex-1 items-center justify-center cursor-pointer text-custom-1 py-4 px-6 md:py-6 md:px-14 btn-outline-black mr-1 sm:mr-2"
                    @click="exit"
                  >
                    <div
                      class="text-[0.6rem] xs:text-[0.7rem] sm:text-base md:text-md"
                    >
                      {{ localStep.exit_button }}
                    </div>
                  </a>
                  <Spacer
                    v-if="
                      !localStep.back_button &&
                      !localStep.exit_button &&
                      !localStep.not_mint_button &&
                      !(localStep.next_button && localStep.back_to_mint)
                    "
                  />
                  <a
                    v-if="localStep.next_button"
                    class="flex flex-1 items-center justify-center cursor-pointer text-custom-1 py-4 px-6 md:py-6 md:px-14 btn-primary-black mr-1 sm:mr-2"
                    @click="nextStep()"
                  >
                    <div
                      class="text-[0.6rem] xs:text-[0.7rem] sm:text-base md:text-md"
                    >
                      {{ localStep.next_button }}
                    </div>
                  </a>
                  <a
                    v-if="localStep.back_to_mint"
                    class="flex flex-1 items-center justify-center cursor-pointer text-custom-1 py-4 px-6 md:py-6 md:px-14 btn-primary-black mr-1 sm:mr-2"
                    @click="previousStep(localStep.backSteps || 1)"
                  >
                    <div
                      class="text-[0.6rem] xs:text-[0.7rem] sm:text-base md:text-md"
                    >
                      {{ localStep.back_to_mint }}
                    </div>
                  </a>
                </div>
                <div
                  v-if="localStep.options"
                  class="mt-8 lg:mt-11 text-center flex justify-between"
                >
                  <a
                    v-for="(option, i) in localStep.options"
                    :key="'option' + i + step"
                    @click="setAnswer(i)"
                    class="flex flex-1 btn-outline-black items-center justify-center cursor-pointer text-custom-1 py-4 px-6 md:py-6 md:px-14 mt-2 md:mt-0 mr-1 remove-focus"
                    :class="{
                      'hover:text-black hover:bg-transparent': isTouchDevice,
                    }"
                  >
                    <div
                      v-html="option"
                      class="text-[0.6rem] xs:text-[0.7rem] sm:text-base md:text-md flex"
                    ></div>
                  </a>
                </div>
              </div>
            </bubble-cloud>
          </div>
          <button
            v-if="!isSkipped && step > 0 && step < 5"
            @click="skip"
            class="float-right mr-8 text-[#555]"
          >
            skip >>
          </button>
          <img
            :src="localStep.icon || '/imgs/favicon.svg'"
            class="w-[80px] h-[80px] mt-8"
          />
        </template>
        <!-- <component :is="localStep.component"/> -->
        <MintForm
          v-else-if="localStep.component == 'MintForm'"
          :background="homePage.experience[step].background"
          :answers="answers"
        />
      </div>
      <div class="flex justify-between w-full px-4">
        <a
          v-if="!isSkipped"
          @click="exit"
          class="btn-primary items-center justify-center cursor-pointer text-custom-1 py-1 px-3 md:py-3 md:px-4 mb-1"
          :class="{
            'hover:text-black hover:bg-transparent': isTouchDevice,
          }"
        >
          <img
            src="/imgs/arrow-right.svg"
            class="mr-1 inline-block invert rotate-180"
          />
          Return
        </a>
        <spacer />
        <img
          src="/imgs/datalatte.svg"
          class="h-[40px] w-[100px] right-[10px]"
        />
      </div>
    </div>
  </Modal>
</template>

<script setup>
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  provide,
  reactive,
  ref,
} from "vue";
import homePage from "@/assets/data/homePage.json";
import Modal from "../modal.vue";
import bubbleCloud from "./bubble-cloud.vue";
import Spacer from "../spacer.vue";
import Rate from "./rate.vue";
import MintForm from "./mint/index.vue";
import Terminal from "./terminal.vue";
import ImageChanger from "./image-changer.vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const visible = ref(false);
provide("visible", visible);

const terminalRef = ref(null);
const isSkipped = ref(false);

const dataSafety = ref(false);
const step = ref(0);
const innerStep = ref(0);
const answers = reactive({});

const fadeOutSpeed = 0;
const fadeInSpeed = 300;

const documentHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
};

const isTouchDevice = computed(
  () =>
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
);

onBeforeMount(() => toggleModal());

onMounted(() => {
  window.addEventListener("resize", documentHeight);
  documentHeight();

  if (route.query?.email && route.query?.answers) {
    [...route.query?.answers].forEach((answer, i) => {
      answers[i] = answer;
    });
    step.value = 5;
    innerStep.value = 1;
  }
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", documentHeight);
});

const localStep = computed(() => {
  if (isSkipped.value) return homePage.experience_skip[0].data[innerStep.value];

  const currentStep = homePage.experience[step.value];
  return currentStep.dependOnPrevious
    ? currentStep.data[answers[step.value - 1]]
    : currentStep.data[innerStep.value];
});

const toggleModal = () => {
  visible.value = !visible.value;
};

const exit = () => {
  if (window.history.state?.back)
    router.replace({ path: window.history.state?.back });
  else router.replace({ path: "/" });
};

const showTerminal = () => {
  const terminalContainer = document.querySelector("#terminal-container");
  terminalContainer.classList.remove("scale-y-0");
  terminalContainer.classList.add("scale-y-1");
  terminalContainer.classList.remove("h-0");
  terminalContainer.classList.add("h-fit");
};

const hideTerminal = () => {
  const terminalContainer = document.querySelector("#terminal-container");
  terminalContainer.classList.add("scale-y-0");
  terminalContainer.classList.remove("scale-y-1");
  terminalContainer.classList.add("h-0");
  terminalContainer.classList.remove("h-fit");
};

const skip = () => {
  $("#experience-content").fadeOut(fadeOutSpeed);
  innerStep.value = 0;
  isSkipped.value = true;
  hideTerminal();
  $("#experience-content").fadeIn(fadeInSpeed);
};

const toggleMinting = () => {
  $("#experience-content").fadeOut(fadeOutSpeed);
  innerStep.value = 0;
  step.value = 6;
  $("#experience-content").fadeIn(fadeInSpeed);
};

const nextStep = (answer = null) => {
  $("#experience-content").fadeOut(fadeOutSpeed);
  if (isSkipped.value) {
    if (homePage.experience_skip[0].data.length - 1 > innerStep.value)
      innerStep.value += 1;
  } else {
    const currentStep = homePage.experience[step.value];
    if (
      localStep.value.mint_title ||
      (answer != null &&
        localStep.value.mint_titles &&
        localStep.value.mint_titles[answer])
    )
      terminalRef.value.addItems([
        localStep.value.mint_title || localStep.value.mint_titles[answer],
        "contract",
        localStep.value.xp,
      ]);

    if (
      !currentStep.dependOnPrevious &&
      currentStep?.data?.length - 1 > innerStep.value
    ) {
      innerStep.value += 1;
    } else if (homePage.experience.length - 1 > step.value) {
      innerStep.value = 0;
      step.value += 1;

      if (step.value != 0) showTerminal();
    }
  }
  $("#experience-content").fadeIn(fadeInSpeed);
};

const previousStep = (strictSteps = 0) => {
  $("#experience-content").fadeOut(fadeOutSpeed);
  if (isSkipped.value) {
    if (!strictSteps && innerStep.value > 0) {
      innerStep.value -= 1;
    } else {
      isSkipped.value = false;
      innerStep.value = 0;
      showTerminal();
    }
  } else {
    if (localStep.value.mint_titles || localStep.value.mint_title)
      terminalRef.value.deleteItems(3);

    if (!strictSteps && innerStep.value > 0) {
      innerStep.value -= 1;
      showTerminal();
    } else if (step.value > 0) {
      innerStep.value = 0;
      step.value -= strictSteps || 1;

      if (step.value == 0) {
        hideTerminal();
      } else showTerminal();
    }
  }
  $("#experience-content").fadeIn(fadeInSpeed);
};

const setAnswer = (ans) => {
  answers[step.value] = ans;
  for (let i = 1; i < 5; i++) {
    if (i <= step.value) continue;
    answers[i] = undefined;
  }

  nextStep(ans);
};
</script>

<style scoped>
:root {
  --doc-height: 100%;
}

.remove-focus:focus {
  background-color: transparent !important;
}

.background-animation {
  -webkit-animation: background-scale-50 15s ease-in-out infinite;
  animation: background-scale-50 15s ease-in-out infinite;
  background-position: center center;
  height: var(--doc-height) !important;
}

@media screen and (max-width: 820px) {
  .background-animation {
    -webkit-animation: unset;
    animation: unset;
    background-size: 100%;
  }
}

@media screen and (min-width: 821px) {
  .background-animation {
    -webkit-animation: background-scale-50 15s ease-in-out infinite;
    animation: background-scale-50 15s ease-in-out infinite;
  }
}

@keyframes background-scale-100 {
  from,
  to {
    background-size: 100%;
  }
  50% {
    background-size: 98%;
  }
}

@keyframes background-scale-50 {
  from,
  to {
    background-size: 50%;
  }
  50% {
    background-size: 45%;
  }
}
</style>
