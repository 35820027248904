<template>
  <transition name="scale-up">
    <div v-if="visible" id="experience-modal" class="modal">
      <slot />
    </div>
  </transition>
</template>

<script setup>
import { onUnmounted, watch } from "vue";

const props = defineProps({
  visible: Boolean,
});

const modal = document.querySelector("#experience-modal");
const body = document.querySelector("body");

const disableScroll = () => {
  body.classList.add("overflow-hidden");
};

const enableScroll = () => {
  body.classList.remove("overflow-hidden");
};

watch(
  props,
  (value) => {
    if (props.visible) {
      disableScroll();
    } else {
      enableScroll();
    }
  },
  { immediate: true }
);

onUnmounted(() => enableScroll());
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s;
}

.scale-up-leave-active,
.scale-up-enter-active {
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}

.scale-up-enter-from,
.scale-up-leave-to {
  transform: scale(0.85);
  opacity: 0;
}
</style>
