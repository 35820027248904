<template>
  <div
    class="lg:flex-1 w-full self-center custom-border p-6 duration-200 ease-in-out"
    :class="[
      disabled ? 'border-slate-400' : 'border-black',
      'bg-[' + background + ']',
    ]"
  >
    <div
      class="text-md sm:text-xl text-font-grand"
      :class="disabled ? 'text-[gray]' : 'text-black'"
    >
      Send yourself a claim link:
    </div>
    <div class="sm:flex block mt-2">
      <div class="flex-1 w-5/5 sm:w-4/5 sm:w-auto sm:mr-4 mr-0">
        <input
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
          :disabled="disabled"
          placeholder="yourm@il.com"
          class="w-full bg-white bg-opacity-80 rounded-10px border focus:bg-opacity-100 focus:border-black focus:ring-1 focus:ring-white text-md sm:text-lg outline-none py-3 px-3 transition-colors duration-200 ease-in-out grow invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
          :class="disabled ? 'border-slate-400' : 'border-black'"
        />
      </div>
      <base-button
        :disabled="disabled"
        color="#93B9FA"
        :loading="!!loading"
        btnClass="mt-2 sm:mt-0 sm:w-1/5"
        @onClick="checkEmail"
      >
        <span :class="disabled ? 'text-[gray]' : 'text-black'">Send</span>
      </base-button>
    </div>
  </div>
</template>

<script setup>
import BaseButton from "../../../base-button.vue";
import { regex } from "@/constants/validations";
import { useToast } from "vue-toastification";

const props = defineProps({
  modelValue: String,
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  background: String,
});

const emits = defineEmits(["update:modelValue", "mint"]);

const toast = useToast();

const checkEmail = () => {
  if (regex.email.test(props.modelValue)) {
    emits("mint");
  } else {
    toast.error("Please Enter a valid mail address.");
  }
};
</script>

<style scoped>
.custom-border {
  border-width: 1px;
  border-style: solid;
  border-radius: 1rem;
}
.disabled {
  color: grey;
  border-color: grey;
}
</style>
