<template>
  <!-- ====== Hero Section Start -->
  <div
    id="hero"
    class="flex items-center bg-custom-green min-h-screen h-auto pt-16 pb-6 lg:pt-14 bg-nonehero-bg dark:bg-custom-green-dark"
  >
    <div class="container lg:px-0 lg:flex lg:items-center">
      <div class="lg:flex-1 lg:order-1">
        <img
          src="/imgs/about/cover.svg"
          alt=""
          class="w-9/12 sm:w-8/12 lg:w-10/12 mx-auto"
        />
      </div>
      <div
        class="prose prose-black px-8 sm:px-12 md:px-20 lg:px-0 pt-3 lg:pt-0 flex-1 xl:flex-none"
      >
        <h1
          class="text-font-heavy text-2xl sm:text-3xl md:text-4xl xl:text-5xl text-black uppercase dark:text-white"
          v-html="aboutPage.hero.title"
        ></h1>
        <p
          class="text-xl md:text-2xl lg:text-3xl mt-5 dark:text-white"
          v-html="aboutPage.hero.subtitle"
        ></p>
        <div class="mt-8 lg:mt-11 uppercase">
          <a
            :href="aboutPage.hero.btn1_link"
            class="inline-block text-custom-1 font-medium py-2 px-4 sm:py-3 sm:px-7 btn-primary mr-1 sm:mr-2"
          >
            <span v-html="aboutPage.hero.btn1" />
          </a>
          <a
            :href="aboutPage.hero.btn2_link"
            class="inline-block text-custom-1 font-medium py-2 px-3 sm:py-3 sm:px-6 btn-outline mt-2 md:mt-0"
          >
            <span v-html="aboutPage.hero.btn2" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- ====== Hero Section End -->

  <!-- ====== Who we are Section Start -->
  <section class="pt-24">
    <div class="container px-4 sm:px-8 md:px-16 lg:px-0">
      <div class="flex flex-wrap items-center">
        <div class="w-full lg:w-7/12 px-4 pt-8">
          <h2
            class="text-font-heavy text-xl xs:text-2xl sm:text-3xl md:text-[40px] text-dark mb-5 uppercase dark:text-white"
            v-html="aboutPage.who.title"
          ></h2>
          <p
            class="text-black-700 text-md sm:text-lg mb-5 lg:mb-11 dark:text-gray-300 text-justify"
          >
            {{ aboutPage.who.desc }}
          </p>
        </div>
        <div class="w-full lg:w-5/12 px-4">
          <img
            :src="`/imgs/${aboutPage.who.img}`"
            alt=""
            class="w-9/12 mx-auto lg:ml-auto dark:invert"
          />
        </div>
      </div>
    </div>
  </section>
  <!-- ====== Who we are Section End -->

  <!-- ====== OUR VISION Section Start -->
  <section class="pt-24">
    <div class="container px-4 sm:px-8 md:px-16 lg:px-0">
      <div class="flex flex-wrap items-center flex-row-reverse">
        <div class="w-full lg:w-7/12 px-4 pt-8">
          <h2
            class="text-font-heavy text-xl xs:text-2xl sm:text-3xl md:text-[40px] text-dark mb-5 uppercase dark:text-white"
            v-html="aboutPage.vision.title"
          ></h2>
          <p
            class="text-black-700 text-md sm:text-lg mb-5 lg:mb-11 dark:text-gray-300 text-justify"
          >
            {{ aboutPage.vision.desc }}
          </p>
        </div>
        <div class="w-full lg:w-5/12 px-4">
          <img
            :src="`/imgs/${aboutPage.vision.img}`"
            alt=""
            class="w-9/12 mx-auto lg:mr-auto dark:invert"
          />
        </div>
      </div>
    </div>
  </section>
  <!-- ====== OUR VISION Section End -->

  <!-- ====== OUR Mission Section Start -->
  <section class="pt-24">
    <div class="container px-4 sm:px-8 md:px-16 lg:px-0">
      <div class="flex flex-wrap items-center">
        <div class="w-full lg:w-7/12 px-4 pt-8">
          <h2
            v-html="aboutPage.mission.title"
            class="text-font-heavy text-xl xs:text-2xl sm:text-3xl md:text-[40px] text-dark mb-5 uppercase dark:text-white"
          ></h2>
          <p
            class="text-black-700 text-md sm:text-lg mb-5 lg:mb-11 dark:text-gray-300 text-justify"
          >
            {{ aboutPage.mission.desc }}
          </p>
        </div>
        <div class="w-full lg:w-5/12 px-4">
          <img
            :src="`/imgs/${aboutPage.mission.img}`"
            alt=""
            class="w-9/12 mx-auto lg:ml-auto dark:invert"
          />
        </div>
      </div>
    </div>
  </section>
  <!-- ====== OUR Mission Section End -->

  <!-- ====== our values Section Start -->
  <section class="pt-28">
    <div class="container px-4 sm:px-8 md:px-16 lg:px-0">
      <div class="text-center mb-16">
        <div class="w-full px-4">
          <div class="">
            <h2
              class="text-font-heavy text-xl xs:text-2xl sm:text-3xl md:text-[40px] text-dark mb-4 uppercase dark:text-white"
            >
              {{ aboutPage.values.title }}
            </h2>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-10">
        <div
          class="w-full px-4"
          v-for="(item, i) in aboutPage.values.values"
          :key="'values' + i"
        >
          <div class="mb-12">
            <h4
              class="text-font-bold text-lg sm:text-xl text-dark mb-3 dark:text-white"
            >
              {{ item.title }}
            </h4>
            <p
              class="text-black-700 text-md sm:text-lg mb-5 lg:mb-11 dark:text-gray-300 text-justify"
            >
              {{ item.desc }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ====== our values Section End -->

  <!-- ====== roadmap Section Start -->
  <!-- <section class="pt-28 hidden md:block">
    <div class="container px-4 sm:px-8 md:px-16 lg:px-0">
      <div class="text-center mb-16">
        <div class="w-full px-4">
          <div class="">
            <h2
              class="text-font-heavy text-xl xs:text-2xl sm:text-3xl md:text-[40px] text-dark mb-4 uppercase dark:text-white"
            >
              {{ aboutPage.roadmap2.title }}
            </h2>
          </div>
        </div>
      </div>
      <div class="">
        <div class="container mx-auto w-full h-full">
          <div class="relative wrap overflow-hidden p-10 h-full">
            <div
              class="absolute border-opacity-20 border-gray-700 h-full border dark:border-gray-300"
              style="left: 50%; bottom: 50px"
            ></div>
            <div
              class="absolute border-opacity-20 border-gray-700 border dark:border-gray-300"
              style="left: 50%; height: 50px; bottom: 0; border-style: dashed"
            ></div>

            <span
              v-for="(item, i) in aboutPage.roadmap2.roadmap"
              :key="'roadmap' + i"
            >
              <div
                v-if="item.title == 'time'"
                class="mb-8 flex justify-between items-center w-full right-timeline mt-4"
              >
                <div class="order-1 w-5/12"></div>
                <div
                  class="z-20 flex items-center order-1 bg-gray-800 rounded-xl py-2 px-3 text-center dark:bg-gray-300"
                >
                  <h1
                    class="mx-auto font-semibold text-lg text-white dark:text-black text-justify"
                    v-html="item.desc"
                  ></h1>
                </div>
                <div class="order-1 rounded-lg w-5/12 px-6 py-4"></div>
              </div>

              <div
                v-else
                class="mb-8 flex justify-between items-center w-full"
                :class="{ 'flex-row-reverse': item.id % 2 != 0 }"
              >
                <div class="order-1 w-5/12"></div>
                <div
                  class="z-20 flex items-center order-1 bg-gray-800 w-8 h-8 rounded-full dark:bg-gray-300"
                >
                  <h1
                    class="mx-auto text-white font-semibold text-lg dark:text-black"
                  >
                    {{ item.id }}
                  </h1>
                </div>
                <div
                  class="order-1 border-2 border-black text-black rounded-lg w-5/12 px-6 py-4 dark:border-white"
                >
                  <h3 class="mb-3 font-bold text-lg sm:text-xl dark:text-white">
                    {{ item.title }}
                  </h3>
                  <p
                    class="font-medium text-sm sm:text-base leading-snug tracking-wide text-opacity-100 dark:text-gray-300 text-justify"
                  >
                    {{ item.desc }}
                  </p>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- ====== roadmap Section End -->

  <!-- ====== roadmap Section Start -->
 <!-- <section class="pt-28 md:hidden">
    <div class="container px-4 sm:px-8 md:px-16 lg:px-0">
      <div class="text-center mb-16">
        <div class="w-full px-4">
          <div class="">
            <h2
              class="text-font-heavy text-xl xs:text-2xl sm:text-3xl md:text-[40px] text-dark mb-4 uppercase dark:text-white"
            >
              {{ aboutPage.roadmap.title }}
            </h2>
          </div>
        </div>
      </div>
      <div class="">
        <div class="container mx-auto w-full h-full">
          <div class="relative wrap overflow-hidden p-4 sm:p-10 h-full">
            <div
              class="absolute border-opacity-20 border-gray-700 h-full border dark:border-gray-300"
              style="bottom: 50px"
            ></div>
            <div
              class="absolute border-opacity-20 border-gray-700 border dark:border-gray-300"
              style="height: 50px; bottom: 0; border-style: dashed"
            ></div>

            <span
              v-for="(item, i) in aboutPage.roadmap2.roadmap"
              :key="'roadmap2' + i"
            >
              <div
                v-if="item.title == 'time'"
                class="mb-8 flex justify-between items-center w-full right-timeline mt-4"
              >
               
                <div
                  class="z-20 flex items-center order-1 bg-gray-800 rounded-xl py-2 px-3 text-center relative left-[-15px] dark:bg-gray-300"
                >
                  <h1
                    class="mx-auto font-semibold text-lg text-white dark:text-black text-justify"
                    v-html="item.desc"
                  ></h1>
                </div>
                <div class="order-1 rounded-lg w-5/12 px-6 py-4"></div>
              </div>

              <div v-else class="mb-8 flex justify-between items-center w-full">
              
                <div
                  class="z-20 flex items-center order-1 bg-gray-800 w-8 h-8 rounded-full relative left-[-15px] dark:bg-gray-300"
                >
                  <h6
                    class="mx-auto text-white font-semibold text-lg dark:text-black"
                  >
                    {{ item.id }}
                  </h6>
                </div>
                <div
                  class="order-1 border-2 border-black text-black rounded-lg w-10/12 px-6 py-4 dark:border-white"
                >
                  <h3 class="mb-3 font-bold text-lg sm:text-xl dark:text-white">
                    {{ item.title }}
                  </h3>
                  <p
                    class="text-sm font-medium text-sm sm:text-base leading-snug tracking-wide text-opacity-100 dark:text-gray-300 text-justify"
                  >
                    {{ item.desc }}
                  </p>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- ====== roadmap Section End -->

  <!-- ====== TEAM Start ====== -->
  <section
    id="team"
    class="py-20 md:py-[120px] mt-12 bg-custom-purple dark:bg-custom-purple-dark"
  >
    <div class="container">
      <div class="text-center mb-16">
        <div class="w-full px-4">
          <div class="">
            <span
              class="font-semibold text-xl sm:text-2xl text-gray-700 mb-2 block uppercase dark:text-gray-300"
            >
              {{ aboutPage.team.miniTitle }}
            </span>
            <h2
              class="text-font-heavy text-xl sm:text-2xl md:text-3xl md:text-[40px] text-dark mb-4 uppercase dark:text-white"
              v-html="aboutPage.team.title"
            ></h2>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap justify-center">
        <div
          class="w-full md:w-1/2 lg:w-1/3 px-3 md:px-2"
          v-for="(item, i) in aboutPage.team.team"
          :key="'team' + i"
        >
          <div
            class="bg-custom-purple mb-4 mx-2 md:mx-0 border border-black rounded-2xl overflow-hidden about-user-card about-user-info cursor-pointer group dark:bg-custom-purple-dark dark:border-white"
          >
            <div class="flex">
              <div
                class="w-[105px] h-[140px] xs:w-[150px] xs:h-[200px] relative bg-contain border-r border-black flex dark:border-white"
                style="background-image: url('../imgs/about/team/pattern2.png')"
              >
                <img
                  :src="`/imgs/${item.img2}`"
                  alt=""
                  class="m-0 w-full opacity-0 group-hover:opacity-100 duration-200 absolute self-center"
                />
                <img
                  :src="`/imgs/${item.img}`"
                  alt=""
                  class="m-0 w-full h-full opacity-100 group-hover:opacity-0 duration-200"
                />
              </div>
              <div class="w-6/12 sm:w-7/12 pl-4">
                <div class="table h-full relative">
                  <div
                    class="table-cell pt-2 sm:pt-0 sm:align-middle dark:text-white"
                  >
                    <h4
                      class="text-font-bold text-md xs:text-lg sm:text-xl w-full"
                      v-html="item.name"
                    ></h4>
                    <p class="w-full text-xs xs:text-sm sm:text-base">
                      {{ item.title }}
                    </p>
                  </div>
                  <div
                    class="absolute bottom-[5px] left-0 opacity-0 group-hover:opacity-100 duration-200"
                  >
                    <span v-if="item.link">
                      <a
                        v-for="link in item.link"
                        :key="link"
                        :href="link.link"
                        target="_blank"
                        class="inline-block mr-1 hover:scale-105 duration-200 border border-black-900 p-[3px] rounded-lg w-[30px] h-[30px] dark:invert"
                        @click="event.stopPropagation()"
                      >
                        <svg
                          v-if="link.website == 'github'"
                          viewBox="0 0 20.06 19.57"
                          class="w-full h-full"
                        >
                          <g id="Layer_x0020_1">
                            <path
                              class="fil0"
                              d="M10.03 0c-5.54,0 -10.03,4.49 -10.03,10.03 0,4.43 2.87,8.19 6.86,9.52 0.5,0.09 0.69,-0.22 0.69,-0.48 0,-0.24 -0.01,-0.87 -0.02,-1.71 -2.79,0.61 -3.38,-1.34 -3.38,-1.34 -0.45,-1.16 -1.11,-1.47 -1.11,-1.47 -0.91,-0.62 0.07,-0.61 0.07,-0.61 1,0.07 1.53,1.03 1.53,1.03 0.9,1.54 2.35,1.09 2.92,0.84 0.09,-0.65 0.35,-1.09 0.64,-1.34 -2.23,-0.26 -4.57,-1.12 -4.57,-4.96 0,-1.1 0.39,-1.99 1.03,-2.69 -0.1,-0.26 -0.44,-1.28 0.1,-2.66 0,0 0.84,-0.27 2.76,1.03 0.8,-0.22 1.66,-0.33 2.51,-0.34 0.85,0.01 1.71,0.12 2.51,0.34 1.92,-1.3 2.76,-1.03 2.76,-1.03 0.55,1.38 0.2,2.4 0.1,2.66 0.64,0.7 1.03,1.59 1.03,2.69 0,3.85 -2.35,4.7 -4.58,4.95 0.36,0.31 0.68,0.92 0.68,1.86 0,1.34 -0.01,2.42 -0.01,2.75 0,0.27 0.18,0.58 0.69,0.48 3.98,-1.33 6.85,-5.09 6.85,-9.52 0,-5.54 -4.49,-10.03 -10.03,-10.03z"
                            />
                          </g>
                        </svg>
                        <svg
                          v-else-if="link.website == 'twitter'"
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z"
                          />
                        </svg>
                        <svg
                          v-else
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          class="w-full h-full p-[2px]"
                        >
                          <path
                            d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"
                          />
                        </svg>
                        <!-- <svg width="18" height="18" viewBox="0 0 18 18" class="fill-current w-full h-full">
                                            <path d="M16.7821 0.947388H1.84847C1.14272 0.947388 0.578125 1.49747 0.578125 2.18508V16.7623C0.578125 17.4224 1.14272 18 1.84847 18H16.7257C17.4314 18 17.996 17.4499 17.996 16.7623V2.15757C18.0525 1.49747 17.4879 0.947388 16.7821 0.947388ZM5.7442 15.4421H3.17528V7.32837H5.7442V15.4421ZM4.44563 6.2007C3.59873 6.2007 2.94944 5.5406 2.94944 4.74297C2.94944 3.94535 3.62696 3.28525 4.44563 3.28525C5.26429 3.28525 5.94181 3.94535 5.94181 4.74297C5.94181 5.5406 5.32075 6.2007 4.44563 6.2007ZM15.4835 15.4421H12.9146V11.509C12.9146 10.5739 12.8864 9.33618 11.5596 9.33618C10.2045 9.33618 10.0069 10.3813 10.0069 11.4265V15.4421H7.438V7.32837H9.95046V8.45605H9.9787C10.3457 7.79594 11.1644 7.13584 12.4347 7.13584C15.0601 7.13584 15.54 8.7861 15.54 11.0414V15.4421H15.4835Z" />
                                        </svg> -->
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="about-user-content border-t border-black p-3 bg-[#fff] dark:border-white text-justify"
              style="display: none"
              v-html="item.desc"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ====== TEAM End ====== -->
</template>

<script setup>
import aboutPage from "@/assets/data/aboutPage.json";
const { onMounted } = require("@vue/runtime-core");

onMounted(() => {
  $(".about-user-card").click(function () {
    if ($(this).find(".about-user-content")[0].style.display == "none") {
      $(".about-user-content").slideUp();
      $(".about-user-card").removeClass("z-[4]");
      $(".about-user-card img").parent().css("transform", "");
      $(".about-user-card").removeClass("bg-[#fff]");
      // $('.about-user-card').find('.about-user-info').removeClass('bg-[#fff]');
      $(this).addClass("z-[4]");
      $(this).addClass("bg-[#fff]");
      // $(this).find('.about-user-info').addClass('bg-[#fff]');
      $(this).find(".about-user-content").slideDown();
      // $(this).find('img').parent().css("transition", "0.3s").css("transform", "scale(1.2)");
    } else {
      var dis = $(this);
      $(this)
        .find(".about-user-content")
        .slideUp(400, function () {
          dis.removeClass("z-[4]");
          dis.removeClass("bg-[#fff]");
          // dis.find('.about-user-info').removeClass('bg-[#fff]');
          dis.find("img").parent().css("transform", "");
        });
    }
    // console.log($(this).find('.about-user-content')[0].style.display);
    // $(this).toggleClass('zindex-1');
    // $(this).find('.about-user-content').slideToggle();
  });
  // $('.about-user-card').hover(function () {
  //     // $('.about-user-content').slideUp();
  //     $(this).addClass('zindex-1');
  //     $(this).find('.about-user-content').slideDown();
  // }, function () {
  //     var dis = $(this);
  //     $(this).find('.about-user-content').slideUp(400, function () {
  //         // Animation complete.
  //         dis.removeClass('zindex-1');
  //     });
  // });
});
</script>
