<template>
  <div
    id="hero"
    class="flex items-center bg-custom-blue min-h-screen h-auto hero-bg dark:bg-custom-blue-dark"
  >
    <div class="container px-3 lg:px-0">
      <div class="px-6 lg:px-0 py-36 text-center">
        <img
          :src="`/imgs/usubscribe.svg`"
          alt=""
          class="w-[400px] mx-auto invert-[0.8] dark:invert-0"
        />
        <h3
          class="text-font-heavy text-xl sm:text-2xl lg:text-3xl mt-10 dark:text-white"
        >
          Do you want to unsubscribe?
        </h3>
        <div
          class="flex justify-around flex-wrap w-full sm:w-6/12 mx-auto mt-8"
        >
          <base-button
            :disabled="loading"
            :loading="!!loading"
            :color="null"
            btnClass="flex-1 w-full md:w-5/12 sm:flex-none mt-2 sm:mt-0 flex-shrink-1 btn-outline text-black rounded-10px  duration-200 ease-in-out no-underline focus:outline-none py-3 px-5 font-medium flex items-center uppercase justify-center m-2"
            @onClick="unsubscribeEmail"
          >
            Yes
          </base-button>
          <button
            class="flex-1 w-full md:w-5/12 sm:flex-none mt-2 sm:mt-0 flex-shrink-1 btn-primary text-black rounded-10px hover:opacity-70 duration-200 ease-in-out no-underline focus:outline-none py-3 px-5 font-medium flex items-center uppercase justify-center m-2"
            @click="close"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { BASE_URL } from "@/constants/general";
import { ref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import axios from "axios";
import BaseButton from "@/components/base-button.vue";

const route = useRoute();
const router = useRouter();
const toast = useToast();

const loading = ref(false);

const close = () => {
  router.replace("/");
};

const unsubscribeEmail = () => {
  if (route.query?.e) {
    loading.value = true;
    axios
      .post(BASE_URL + "/unsubscribe", {
        email: route.query?.e,
        ens: "",
      })
      .then((response) => {
        if (response?.data?.ok) {
          toast.success(
            response?.data?.description || "You have successfully unsubscribed."
          );
          close();
        } else {
          toast.error(
            response?.data?.description ||
              "Something went wrong! Please try again."
          );
        }
      })
      .catch((error) => {
        toast.error("Something Went Wrong in api connection.");
      })
      .finally(() => {
        loading.value = false;
      });
  } else toast.error("Email is not available.");
};
</script>

<style></style>
