<template>
  <div>
    <ul class="flex items-center">
      <li v-for="(star, i) in count" :key="'star' + i" class="star">
        <img
          src="/imgs/list-style.svg"
          class="h-[30px] w-[30px]"
          :class="{ deactive: star > modelValue }"
        />
      </li>
    </ul>
    <div v-if="hasCounter" class="text-center">({{ modelValue }} / {{ count }})</div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Number,
    },
    count: {
      type: Number,
      required: true,
    },
    hasCounter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.deactive {
  opacity: 0.2;
}
</style>
