<template>
  <template v-if="!resultType.length">
    <div class="flex flex-col-reverse sm:flex-row mt-8 mb-4">
      <div
        class="my-auto flex items-center p-3 mt-4 max-w-[100px] aspect-square sm:w-auto mx-auto sm:mx-0 sm:ml-2 bg-[#ffffffa0] rounded-lg backdrop-blur-md sm:my-auto cursor-pointer"
        @click="isVisible = !isVisible"
      >
        <ImageChanger
          :modelValue="isVisible"
          firstSrc="/imgs/show.png"
          secondSrc="/imgs/hide.png"
          firstClass="w-[40px] h-[40px]"
          secondClass="w-[40px] h-[40px]"
        />
      </div>
      <div
        class="w-full sm:w-7/12 md:w-7/12 lg:w-6/12 mx-auto z-0 relative -translate-50"
      >
        <div class="flex items-center align-center sm:-ml-[80px]">
          <div
            v-if="loading"
            class="max-w-[459px] w-full aspect-square text-center flex justify-center"
          >
            <CircleLoader size="50px" class="aspect-square" />
          </div>
          <div v-else @click="isVisible = !isVisible" class="w-full">
            <ImageChanger
              :modelValue="isVisible"
              with-loader
              :firstSrc="nfts.public"
              :secondSrc="nfts.private"
              firstClass="w-10/12 sm:w-full"
              secondClass="w-10/12 sm:w-full"
            />
          </div>
        </div>
      </div>
    </div>
    <Form
      :background="background"
      :answers="answers"
      @update:transactionHash="(code) => (transactionHash = code)"
      @update:resultType="(code) => (resultType = code)"
    />
  </template>
  <div
    v-else-if="resultType == 'email'"
    class="text-center bg-[#ffffffa0] p-5 py-10 rounded-xl backdrop-blur-sm"
  >
    <h1
      class="text-font-heavy text-md sm:text-xl md:text-2xl text-center text-black"
    >
      Email sent successfully
    </h1>
    <div
      class="text-font-grand text-sm sm:text-lg md:text-xl text-center text-black my-8"
    >
      We sent you an email, you can finish minting your NFT by following the
      link there.
    </div>
    <base-button class="w-8/12 sm:w-5/12 mx-auto mt-4" @onClick="goHome">
      Done
    </base-button>
  </div>
  <div
    v-else
    class="text-center bg-[#ffffffa0] p-5 py-10 rounded-xl backdrop-blur-sm"
  >
    <h1
      class="text-font-heavy text-md sm:text-xl md:text-2xl text-center text-black"
    >
      🎊🎉 Congratulations 🎉🎊
    </h1>
    <div
      class="text-font-grand text-sm sm:text-lg md:text-xl text-center text-black my-8"
    >
      You have successfully minted your datalatte2go NFT to your wallet. Here is
      the proof:
    </div>
    <base-button
      class="w-8/12 sm:w-5/12 mx-auto my-4 py-3 px-8 border border-[#8247e5] hover:shadow-lg hover:shadow-[#8247e580]"
      color="transparent"
      @onClick="openURL"
    >
      <img src="/imgs/home/polygon2.png" class="w-full mx-auto" />
    </base-button>
    <base-button class="w-8/12 sm:w-5/12 mx-auto mt-8" @onClick="goHome">
      Done
    </base-button>
  </div>
</template>

<script setup>
import { inject, onMounted, reactive, ref } from "vue";
import ImageChanger from "../image-changer.vue";
import Axios from "axios";
import CircleLoader from "../../circle-loader.vue";
import BaseButton from "../../base-button.vue";
import Form from "./form/index.vue";
import { BASE_URL } from "@/constants/general";
import Spacer from "@/components/spacer.vue";
import { useRouter } from "vue-router";

const props = defineProps({
  background: String,
  answers: Object,
});

const router = useRouter();

const isVisible = ref(true);

const loading = ref(false);
const nfts = reactive({
  public: null,
  private: null,
});

const resultType = ref("");
const transactionHash = ref("");

const getAnswers = () => {
  return Object.keys(props.answers).reduce(
    (val, i) => (i < 5 ? (val += props.answers[i]) : val),
    ""
  );
};

const openURL = () => {
  window.open(transactionHash.value, "_blank");
};

const getNfts = () => {
  loading.value = true;

  Axios.post(BASE_URL + "/sample_nft", {
    answers: getAnswers(),
  })
    .then((response) => {
      nfts.public = response.data?.data?.public;
      nfts.private = response.data?.data?.private;
      loading.value = false;
    })
    .catch((error) => {
      loading.value = false;
    });
};

const goHome = () => {
  router.replace("/");
};

onMounted(getNfts);
</script>

<style scoped>
.custom-border {
  border-width: 1px;
  border-style: solid;
  border-radius: 1rem;
}
.disabled {
  color: grey;
  border-color: grey;
}
.taghe-nosrat {
  background-image: url("../../../../public/imgs/taghe_nosrat.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
