export const ENVIRONMENT_MODE = process.env.NODE_ENV;

// export const BUILD_TYPE = "kiosk";
export const BUILD_TYPE = "main";

export const EXPERIENCE_LATTE_AVAILABILITY = true;

export const BASE_URL =
  ENVIRONMENT_MODE === "development"
    ? "http://ec2-52-59-205-15.eu-central-1.compute.amazonaws.com:8000"
    : "https://j5iafh6god.execute-api.eu-central-1.amazonaws.com";

export default Object.freeze({
  BASE_URL,
  BUILD_TYPE,
  ENVIRONMENT_MODE,
  EXPERIENCE_LATTE_AVAILABILITY,
});
