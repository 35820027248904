<template>
  <switch-view
    backTitle="Email"
    :frontTitle="BUILD_TYPE == 'kiosk' ? 'ENS' : 'WALLET'"
    :titlesClass="BUILD_TYPE == 'kiosk' ? 'pl-[50px]' : 'pl-[33px]'"
    :disabled="route?.query?.email"
  >
    <template #front>
      <ens
        v-if="BUILD_TYPE == 'kiosk'"
        :modelValue="ens"
        @update:modelValue="(val) => (ens = val)"
        :background="background"
        :disabled="!!email.length"
        :loading="loading"
        titlesClass="pr-[50px]"
        @mint="mint"
      />
      <Wallet
        v-else
        :modelValue="ens"
        @update:modelValue="(val) => (ens = val)"
        :background="background"
        :loading="loading"
        @mint="mint"
      />
    </template>
    <template #back>
      <email
        :modelValue="email"
        @update:modelValue="(val) => (email = val)"
        :background="background"
        :disabled="!!ens.length || localEmailDisabled"
        :loading="loading"
        @mint="mint"
      />
    </template>
  </switch-view>
</template>

<script setup>
import { inject, onMounted, ref } from "vue";
import Axios from "axios";
import SwitchView from "../../switch-view.vue";
import Email from "./email.vue";
import Ens from "./ens.vue";
import { useToast } from "vue-toastification";
import Wallet from "./wallet.vue";
import { BASE_URL, BUILD_TYPE } from "@/constants/general";
import { useRoute } from "vue-router";

const props = defineProps({
  background: String,
  answers: Object,
});

const emits = defineEmits(["update:resultType", "update:transactionHash"]);

const toast = useToast();
const route = useRoute();

const email = ref("");
const ens = ref("");

const localEmailDisabled = ref(false);
const loading = ref(false);

onMounted(() => {
  if (route.query?.email) {
    email.value = route.query?.email;
    localEmailDisabled.value = true;
  }
});

const getAnswers = () => {
  return Object.keys(props.answers).reduce(
    (val, i) => (i < 5 ? (val += props.answers[i]) : val),
    ""
  );
};

const mint = () => {
  if (!loading.value) {
    loading.value = true;
    Axios.post(BASE_URL + "/mint", {
      answers: getAnswers(),
      email: email.value,
      ens: ens.value,
      nonce: "",
    })
      .then((response) => {
        if (response?.data?.ok) {
          if (ens?.value?.length) {
            emits("update:resultType", "wallet");
            emits("update:transactionHash", response?.data?.data);
            if (response?.data?.description)
              toast.success(response.data?.description);
          } else {
            emits("update:resultType", "email");
            toast.success(
              response?.data?.description ||
                "We will send you an email for minting."
            );
          }
        } else {
          toast.error(
            response?.data?.description ||
              "Something went wrong! Please try again."
          );
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong! Please try again."
        );
      })
      .finally(() => {
        loading.value = false;
      });
  }
};
</script>

<style scoped>
.custom-border {
  border-width: 1px;
  border-style: solid;
  border-radius: 1rem;
}
.disabled {
  color: grey;
  border-color: grey;
}
.taghe-nosrat {
  background-image: url("../../../../../public/imgs/taghe_nosrat.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
